/* query */
export const LAZY_LIMIT = 999

/* connection */
export const BRIDGE = "https://walletconnect.mises.site"

/* api */
export const ASSETS = "https://assets.mises.site"
export const OBSERVER = "wss://rpc.gw.mises.site/websocket"

/* website */
export const STATION = "https://station.mises.site"
export const FINDER = "https://gw.mises.site"
export const EXTENSION = ""
export const TUTORIAL = "https://www.mises.site/introduction"

/* website: stake */
export const TERRA_VALIDATORS = "https://www.mises.site/validator"

export const STAKE_ID = "https://stakeid.mises.site/#/validator/"

/* ledger */
export const LEDGER_TRANSPORT_TIMEOUT = 120000

/* tx */
export const DEFAULT_GAS_ADJUSTMENT = 2

/* swap */
export const TERRASWAP_COMMISSION_RATE = 0.003

/* placeholder */
export const SAMPLE_ADDRESS = "mises1x46rqay4d3cssq8gxxvqz8xt6nwlz4td20k38v"
