/*
 * @Author: lmk
 * @Date: 2022-05-25 11:23:10
 * @LastEditTime: 2022-06-07 11:37:00
 * @LastEditors: lmk
 * @Description:
 */
import { Col, Page } from "components/layout"
import Validators from "./Validators"

const Stake = () => {
  return (
    <Page>
      <Col>
        {/* <Staked /> */}
        <Validators />
      </Col>
    </Page>
  )
}

export default Stake
